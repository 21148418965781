@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'IranSanse';
    src:local('IranSanse'), url(./fonts/iran-sanse-web/B_Yekan.ttf) format('truetype');
}


.wild-appricot{
    overflow:auto;
    -webkit-overflow-scrolling:touch
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&family=Noto+Naskh+Arabic:wght@400;500&family=Poppins:wght@300;400;500;600;700&family=Sora:wght@300;400&display=swap');
