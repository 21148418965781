
html {
  font-family: "Rubik", sans-serif;
}

.animation-hover:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  height: 2px;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  background-color:#000
  @apply w-0 left-1/2 block bottom-0 absolute;
}
.animation-active:after {
  @apply left-0 w-full;
}
.animation-hover:hover:after {
  @apply left-0 w-full;
}

li.custom-list:before {
  content: "\2022"; /* bullet point, for screen readers */
  text-indent: -9999999px; /* move the bullet point out of sight */

  width: 0.4em;
  height: 1em;
  background-repeat: no-repeat;

  background-size: 0.4em 0.7em;
  background-position: 0 0.3em;
  font-size: 300%;
  top: -0.35em;
  @apply absolute block;
}
li.circle-check:before {
  left: -0.7em;
  top: -0.4em;
}
li.check:before {
  left: -0.5em;
  top: -0.5em;
  font-size: 400%;
}


/* purgecss end ignore */
@tailwind utilities;
/* purgecss start ignore */
/* purgecss end ignore */
